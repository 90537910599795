@font-face {
  font-family: grotesk; 
  src: url('assets/fonts/HKGrotesk-Regular.woff');
}
@font-face {
  font-family: grotesk; 
  src: url('assets/fonts/HKGrotesk-Black.woff');
  font-weight: bold;
}

@font-face {
  font-family: grotesk; 
  src: url('assets/fonts/HKGrotesk-Light.woff');
  font-weight: 100;
}
                   
* {
  box-sizing: border-box;
}
html, body, #root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  user-select:none;
  font-family: grotesk, sans-serif;
  height: 100%;
  overflow: hidden;
  background: #2d2d2d;
  /* background: #17181b; */
  color: #fff;
  margin: 0;
  padding: 0;
  cursor: url('assets/cursor.png') 39 39, auto;

}
#root {
  position: absolute;
}
button {
  font-family: inherit;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button{
  appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}


::-webkit-scrollbar {
  width: 6px;
  /* display: none;
  overflow: visible; */
}

::-webkit-scrollbar-track {
  /* background: rgba(255,255,255, 0.05); */
  background: transparent;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #131724;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
:root {
  --doc-height: 100%;
 }
 button {
  padding: 0;
  background: transparent;
  border: 0;
  cursor: pointer;
  transition: 0.1s ease-in;
 }